.App {
  background-color: #f5f6fa;
  height: 100vh;
}
.z-auto-full {
  z-index: 999999;
}
.divider {
  width: 1px;
  border-left: solid 1px #e5e8eb;
  padding-top: 16px;
  padding-bottom: 10px;
  height: 100px;
}
.list-shadow {
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
}
.min-width-190 {
  min-width: 190px;
}
.border-circle {
  border-radius: 50%;
}
::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
}
.react-datepicker__day--keyboard-selected {
  background-color: transparent !important;
  color: inherit !important;
}
.react-datepicker__day--keyboard-selected.react-datepicker__day--today {
  background-color: #2579ba !important;
  color: #fff !important;
}

