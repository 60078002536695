.table {
    display: table;
    width: 100%;
}
.row {
    display: table-row;
}
.header {
    display: table-header-group;
    box-shadow: inset 0px 1px 0px #e5e8eb, inset 0px -1px 0px #e5e8eb;
}
.th {
    display: table-cell;
    min-width: 120px;
}
.tbody {
    display: table-row-group;
}
.footer {
    box-shadow: inset 0px 1px 0px #e5e8eb;
}
.pager-span {
    min-width: 32px !important;
    height: 32px;
}
.current-page {
    border-radius: 43px;
    background-color: black;
    color: white;
}
