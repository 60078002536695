.react-datepicker-year-header {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    background-color: white;
    border-bottom: none;
    display: flex;
    align-items: center;
    justify-content: center;
}
.react-datepicker__header {
    background-color: white;
    border-bottom: none;
}
.rmdp-ep-arrow {
    display: none;
}
.react-datepicker__triangle {
    display: none;
}
.react-datepicker {
    border: none;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    display: flex;
    flex-direction: row;
}
.react-datepicker__month-text {
    font-family: 'Inter';
    font-style: normal;
    width: 78px !important;
    height: 40px;
    border-radius: 4px;
    padding-top: 10px;
    border: 1px solid #d1d6db;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #4e5968;
    background-color: #fff;
}
.react-datepicker__month--disabled {
    color: #d1d6db;
    border: none;
}
.react-datepicker__input-container-button {
    height: 40px;
    border-radius: 4px;
    padding-left: 16px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    outline: none;
}
.react-datepicker__navigation {
    top: 10px;
}
.date-range-selector {
    height: 40px;
    border-radius: 4px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    vertical-align: middle;
    min-width: 230px;
}
.react-datepicker__day--outside-month {
    pointer-events: none;
}
.react-datepicker__day--in-range:hover, .react-datepicker__day--in-range {
    background-color: #fff;
    color: #000;
}
.react-datepicker__day--in-range:not(.react-datepicker__day--outside-month), .react-datepicker__day--in-range:hover:not(.react-datepicker__day--outside-month) {
    background-color: #e8f3ff;
    color: #000;
}
.react-datepicker__day, .react-datepicker__day:hover {
    border-radius: 0;
    margin-left: 0;
    margin-right: 0;
}
.react-datepicker__day--range-end, .react-datepicker__day--range-start, .react-datepicker__day--range-start:hover, .react-datepicker__day--selecting-range-start {
    background-color: #3179f6 !important;
    color: #fff !important;
    border-radius: 18px 0px 0px 18px;
}
.react-datepicker__day--range-end, .react-datepicker__day--range-end:hover {
    border-radius: 0px 18px 18px 0px;
}
.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--selecting-range-start) {
    background-color: #e8f3ff !important;
    color: #000;
}
.react-datepicker__navigation-icon::before {
    border-color: #ccc;
    border-style: solid;
    border-width: 0 0 0 0;
    content: "";
    display: block;
    height: 9px;
    position: absolute;
    top: 6px;
    width: 9px;
}
.react-datepicker__navigation-icon {
    position: relative;
    top: -1px;
    font-size: 20px;
    width: auto;
}
.react-datepicker__current-month {
    margin-top: 0;
    color: #000;
    font-family: 'Inter';
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 8px;
}
.react-datepicker__day-name {
    color: #6b7684;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    width: 2.25rem;
    height: 16px;
}
.react-datepicker__day-names {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 16px;
    padding-right: 16px;
}
.react-datepicker__week {
    width: 252;
    height: 36px;
}
.react-datepicker__day {
    width: 36px;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 14px;
}
.react-datepicker__navigation--previous {
    left: 8px;
}
.react-datepicker__navigation--next {
    right: 8px;
}
.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
    margin: 0;
}
.react-datepicker__month {
    padding: 8px 16px 16px 16px;
    margin: 0;
}

.react-datepicker_container {
    box-shadow: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
   
}
.datepicker-footer {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
}