@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: Avenir Next LT Pro;
    src        : url('../src/assets/fonts/AvenirNextLTPro-Regular.otf');
}

@font-face {
    font-family: Avenir Next LT Pro B;
    src        : url('../src/assets/fonts/AvenirNextLTPro-Demi.otf');
}

.box-shadow-switch {
    box-shadow   : 0px 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 40px;
}

.border-50-percent {
    border-radius: 50%;
}
/* css for posthog notification bar */
.notification-bar-container {
    min-height: 56px !important;
  }
  .notification-bar {
    justify-content: start !important;
    border-bottom: solid 1px #D1D6DB !important;
  }
  .alert-box {
    display: flex;
    padding: 16px;
    font-family: 'Inter';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    gap: 8px;
  }
  
  .alert-text {
    flex: 1;
    text-align: start;
    padding-top: 4px;
  }
  
  .alert-link {
    text-decoration: none;
  }