.snackbar {
  visibility: hidden;
  width: 400px;
  height: 64px;
  margin-left: -125px;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 16px;
  padding-right: 16px;
  position: fixed;
  z-index: 60;
  left: 50%;
  font-size: 1rem;
  box-shadow: 0px 8px 12px rgba(9, 30, 66, 0.15), 0px 0px 1px rgba(9, 30, 66, 0.31);
}
.snackbar.show-bottom-left {
  visibility: visible;
 /* Show the snackbar */
 /* Add animation: Take 0.5 seconds to fade in and out the snackbar. However, delay the fade out process for 2.5 seconds */
  -webkit-animation: fadein 0.5s, fadeout 0.5s 4.5s;
  animation: fadein 0.5s, fadeout 0.5s 4.5s;
  bottom: 5%;
  left: 15%;
}
.snackbar.show-bottom {
  visibility: visible;
 /* Show the snackbar */
 /* Add animation: Take 0.5 seconds to fade in and out the snackbar. However, delay the fade out process for 2.5 seconds */
  -webkit-animation: fadein 0.5s, fadeout 0.5s 4.5s;
  animation: fadein 0.5s, fadeout 0.5s 4.5s;
  bottom: 30px;
}
.snackbar.show-bottom.always {
  visibility: visible;
 /* Show the snackbar */
 /* Add animation: Take 0.5 seconds to fade in and out the snackbar. However, delay the fade out process for 2.5 seconds */
  -webkit-animation: fadein 0.5s;
  animation: fadein 0.5s;
}
.snackbar.show-top {
  visibility: visible;
 /* Show the snackbar */
 /* Add animation: Take 0.5 seconds to fade in and out the snackbar. However, delay the fade out process for 2.5 seconds */
  -webkit-animation: fadeintop 0.5s, fadeouttop 0.5s 4.5s;
  animation: fadeintop 0.5s, fadeouttop 0.5s 4.5s;
  top: 30px;
  bottom: unset;
}
.snackbar.show-top.always {
  visibility: visible;
 /* Show the snackbar */
 /* Add animation: Take 0.5 seconds to fade in and out the snackbar. However, delay the fade out process for 2.5 seconds */
  -webkit-animation: fadeintop 0.5s;
  animation: fadeintop 0.5s;
  top: 30px;
}
@-webkit-keyframes fadeintop {
  from {
    top: 0;
    opacity: 0;
 }
  to {
    top: 30px;
    opacity: 1;
 }
}
@keyframes fadeintop {
  from {
    top: 0;
    opacity: 0;
 }
  to {
    top: 30px;
    opacity: 1;
 }
}
@-webkit-keyframes fadeouttop {
  from {
    top: 30px;
    opacity: 1;
 }
  to {
    top: 0;
    opacity: 0;
    bottom: unset;
 }
}
@keyframes fadeouttop {
  from {
    top: 30px;
    opacity: 1;
 }
  to {
    top: 0;
    opacity: 0;
    bottom: unset;
 }
}
/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
 }
  to {
    bottom: 30px;
    opacity: 1;
 }
}
@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
 }
  to {
    bottom: 30px;
    opacity: 1;
 }
}
@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
 }
  to {
    bottom: 0;
    opacity: 0;
 }
}
@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
 }
  to {
    bottom: 0;
    opacity: 0;
 }
}
