.topleftlabel {
    position: relative;
}

.topleftlabel:after{
    border: solid;
    content: '';
    position: absolute;
    border-color: var(--dynamic-borderColor, 'transparent'); ;
    top: -1px;
    left: 6px; 
    border-left-width: var(--dynamic-borderLeftWidth, 30px);  
}
.label-flex-g-0{
flex-grow: 0!important;
}