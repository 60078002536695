.rmdp-header-values {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    background-color: white;
    border-bottom: none;
    display: flex;
    align-items: center;
    justify-content: center;
}
.rmdp-header {
    background-color: white;
    border-bottom: none;
    width: 254px;
    margin-left: 18px;
}
.rmdp__triangle {
    display: none;
}
.rmdp-wrapper {
    border: none !important;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
    z-index: 99 !important;
}
.rmdp-shadow {
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0) !important;
}
.rmdp-day {
    font-family: 'Inter';
    font-style: normal;
    width: 78px !important;
    height: 40px !important;
    border-radius: 4px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #4e5968;
    background-color: #fff;
}
.rmdp-ym .rmdp-day.rmdp-today span {
    color: #d1d6db;
    background-color: #fff;
}
.rmdp-ym {
    gap: 12px;
}
.rmdp__month--disabled {
    color: #d1d6db;
    border: none;
}
.rmdp__input-container-button {
    height: 40px;
    border-radius: 4px;
    padding-left: 16px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    outline: none;
}
.rmdp__navigation {
    top: 10px;
}
.date-range-selector {
    height: 40px;
    border-radius: 4px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    vertical-align: middle;
    min-width: 230px;
}
.rmdp__day--outside-month {
    pointer-events: none;
}
.rmdp__day--in-range:hover, .rmdp__day--in-range {
    background-color: #fff;
    color: #000;
}
.rmdp__day--in-range:not(.rmdp__day--outside-month), .rmdp__day--in-range:hover:not(.rmdp__day--outside-month) {
    background-color: #e8f3ff;
    color: #000;
}
.rmdp__day, .rmdp__day:hover {
    border-radius: 0;
    margin-left: 0;
    margin-right: 0;
}
.rmdp__day--range-end, .rmdp__day--range-start, .rmdp__day--range-start:hover, .rmdp__day--selecting-range-start {
    background-color: #3179f6 !important;
    color: #fff !important;
    border-radius: 18px 0px 0px 18px;
}
.rmdp__day--range-end, .rmdp__day--range-end:hover {
    border-radius: 0px 18px 18px 0px;
}
.rmdp__day--in-selecting-range:not(.rmdp__day--selecting-range-start) {
    background-color: #e8f3ff !important;
    color: #000;
}
.rmdp__navigation-icon::before {
    border-color: #ccc;
    border-style: solid;
    border-width: 0 0 0 0;
    content: "";
    display: block;
    height: 9px;
    position: absolute;
    top: 6px;
    width: 9px;
}
.rmdp__navigation-icon {
    position: relative;
    top: -1px;
    font-size: 20px;
    width: auto;
}
.rmdp__current-month {
    margin-top: 0;
    color: #000;
    font-family: 'Inter';
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 8px;
}
.rmdp__day-name {
    color: #6b7684;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    width: 2.25rem;
    height: 16px;
}
.rmdp__day-names {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 16px;
    padding-right: 16px;
}
.rmdp__week {
    width: 252;
    height: 36px;
}
.rmdp__navigation--previous {
    left: 8px;
}
.rmdp__navigation--next {
    right: 8px;
}
.rmdp__day-name, .rmdp__day, .rmdp__time-name {
    margin: 0;
}
.rmdp-month-picker {
    padding: 8px 16px 16px 16px;
    margin: 0;
}
.rmdp-month-picker div {
    margin: auto !important;
    padding-bottom: 12px;
}
.rmdp-top-class {
    gap: 24px;
}
.rmdp-top-class .rmdp-calendar {
    width: 314px !important;
}
.rmdp-panel-body li {
    background-color: white;
    margin: auto;
    border-radius: 0;
    box-shadow: none;
}
.rmdp-panel-body li .b-date {
    color: black;
    text-align: left;
}
.rmdp-day.rmdp-selected span:not(.highlight) {
    box-shadow: none !important;
    background-color: transparent !important;
}
.rmdp-ym .rmdp-day span {
    border-radius: 4px !important;
    padding: 0px !important;
    height: 40px !important;
    position: initial;
    background-color: white;
    color: #4e5968;
    border: 1px solid #d1d6db;
}
.rmdp-day.rmdp-disabled span {
    background-color: #f9fafb;
    color: #d1d6db;
    border: none;
    border-radius: 4;
    border-width: 0;
}
@media (hover: hover) {
    .rmdp-day:not(.rmdp-disabled, .rmdp-day-hidden, .rmdp-selected)span:hover {
        background-color: #e5e8eb;
        color: white;
   }
}
.rmdp-year-picker {
    width: 260px;
    margin-left: 14px;
    height: 208px;
}
.react-datepicker .hover\:bg-grey4:hover {
    background-color: transparent !important;
}
.arrow-disabled {
    color: #d1d6db !important;
}
